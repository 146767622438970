import React from "react";

const TotalScore = ({ score, color }) => {
  return (
    <div className="row my-1 px-3">
      <div
        className={`col-12 text-center py-1 `}
        style={{backgroundColor: '#000000'}}
      >
        <h4 className='text-white py-1'>Revenue Throughput: {score}% <span className={`btn bg-${color} btn-circle ml-3`} /></h4>
        
      </div>
    </div>
  );
};

export default TotalScore;
