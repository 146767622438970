import React from "react";
import Table from "../common/hooksTable/Table";
import { Link } from "react-router-dom";
import Loader from "../common/Loader";
import Search from "../common/search";
import { stringSearch } from "../../utils/functions/generalFunctions";
import { useCompanies } from "../../hooks/companyHooks";
import { useGeneralMutation } from "../../hooks/useGeneralMutation";
import { deleteCompany } from "../../services/companyService";

const CompanyTable = () => {
  const [filtered, setFiltered, allCompanies] = useCompanies();
  const [query, setQuery] = React.useState("");
  const doDelCompany = useGeneralMutation(deleteCompany, ["companies"]);

  const handleSearch = (value) => {
    setQuery(value);
    setFiltered(
      allCompanies.filter((company) => stringSearch(company.name)(value))
    );
  };

  const doDeleteCompany = async (company) => {
    if (!window.confirm("Are you sure you want to delete this company?"))
      return true;
    try {
      await doDelCompany(company._id);
    } catch (ex) {
      console.log(ex.message);
    }
  };

  const columns = [
    {
      path: "name",
      label: "Company Name",
      content: (company) => {
        return <Link to={`/company/${company._id}`}>{company.name}</Link>;
      },
    },

    {
      path: "maps",
      label: "Maps in Use",
      content: (company) => company.maps.length,
    },
    {
      path: "delete",
      label: "Delete",
      content: (company) => (
        <i
          className={`fa fa-times text-danger`}
          style={{ cursor: "pointer" }}
          onClick={() => doDeleteCompany(company)}
        />
      ),
    },
  ];

  if (!allCompanies) return <Loader />;

  return (
    <div className="container">
      <h2>Companies</h2>
      <Search onChange={handleSearch} value={query} />
      <Table columns={columns} data={filtered} sort={true} />
    </div>
  );
};

export default CompanyTable;
