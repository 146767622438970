import React from "react";
import Table from "../common/hooksTable/Table";
import { strToLocal } from "../../utils/functions/dateFunctions";
import { Link, withRouter } from "react-router-dom";
import LinkButtons from "../companyMapManagement/LinkButtons";
import { useCompanyMaps, useUpdateCompany } from "../../hooks/companyHooks";
import Loader from "../common/Loader";
import { stringSearch } from "../../utils/functions/generalFunctions";
import Search from "../common/search";
const R = require("ramda");

const MapTable = ({ companyId }) => {
  const [query, setQuery] = React.useState("");
  const [filteredMaps, setFilteredMaps, allMaps] = useCompanyMaps(companyId);
  const doUpdateCompany = useUpdateCompany(companyId, ["company"]);

  const handleSearch = (value) => {
    setQuery(value);
    setFilteredMaps(allMaps.filter((map) => stringSearch(map.name)(value)));
  };

  const toggleLock = async (map) => {
    try {
      await doUpdateCompany({
        maps: allMaps.map((m) =>
          m._id === map._id ? { ...map, locked: !m.locked } : m
        ),
      });
    } catch (ex) {
      console.log(ex.message);
    }
  };

  const toggleShowCrit = async (map) => {
    try {
      await doUpdateCompany({
        maps: allMaps.map((m) =>
          m._id === map._id ? { ...map, showCrit: !m.showCrit } : m
        ),
      });
    } catch (ex) {
      console.log(ex.message);
    }
  };

  const deleteMap = async (map) => {
    if (!window.confirm("Are you sure you want to delete this map?"))
      return true;
    try {
      await doUpdateCompany({
        maps: allMaps.filter((m) => m._id !== map._id),
      });
    } catch (ex) {
      console.log(ex.message);
    }
  };

  if (R.isEmpty(allMaps)) return <Loader />;

  const columns = [
    {
      path: "name",
      label: "Name",
      content: (map) => (
        <Link to={`/map/${companyId}/${map._id}`}>{map.name}</Link>
      ),
    },
    {
      path: "creationDate",
      label: "Date Created",
      content: (map) => strToLocal(map.creationDate)("en"),
    },
    {
      path: "locked",
      label: "Active",
      content: (map) => (
        <i
          className={`fa fa-${map.locked ? "times" : "check"} text-${
            map.locked ? "danger" : "success"
          }`}
          style={{ cursor: "pointer" }}
          onClick={() => toggleLock(map)}
        />
      ),
    },
    {
      path: "showCrit",
      label: "Show Crit Path",
      content: (map) => {
        return (
          <i
            className={`fa fa-${map.showCrit ? "check" : "times"} text-${
              map.showCrit ? "success" : "danger"
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => toggleShowCrit(map)}
          />
        );
      },
    },
    {
      path: "delete",
      label: "Delete",
      content: (map) => (
        <i
          className={`fa fa-times text-danger`}
          style={{ cursor: "pointer" }}
          onClick={() => deleteMap(map)}
        />
      ),
    },
    {
      path: "link",
      label: "Access Links",
      content: (map) => <LinkButtons map={map} companyId={companyId} />,
    },
  ];

  return (
    <div className="container">
      <Search onChange={handleSearch} value={query} />

      <Table
        columns={columns}
        data={filteredMaps}
        initialDirection={false}
        initialSort="creationDate"
        sort={true}
      />
    </div>
  );
};

export default withRouter(MapTable);
