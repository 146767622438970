import http from "./httpService";

const apiEndpoint = "/users";

export async function register(user) {
  // returning all data to access headers as well.
  return await http.post(apiEndpoint, {
    nameLast: user.nameLast,
    nameFirst: user.nameFirst,
    email: user.email,
    password: user.password,
  });
}

export const getUserInfo = async (_, userId, requestedData) => {
  const { data } = await http.get(
    `${apiEndpoint}/${userId}?requestedData=${requestedData}`,
    {
      requestedData,
    }
  );
  return data;
};

export async function getAllUsers(_, page, requestedData = "", search = "") {
  const { data } = await http.get(
    `${apiEndpoint}?requestedData=${requestedData}&search=${search}&page=${page}`
  );
  return data;
}

export async function checkEmail(email) {
  const { data } = await http.put(`${apiEndpoint}/emailCheck`, {
    email,
  });
  return data;
}

export async function updateUser({ userId, updates }) {
  const { data } = await http.put(`${apiEndpoint}/${userId}`, {
    updates,
  });
  return data;
}

export async function deleteUser( userId ) {
  const { data } = await http.delete(`${apiEndpoint}/${userId}` );
  return data;
}
