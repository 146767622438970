import React from "react"; //  { useContext }
import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import MapTable from "./MapTable";
import NewMapTemplateForm from "./NewMapTemplateForm";

const MapsPage = () => {
  const dispatch = useDispatch();

  const openModal = () =>
    storeUpdater(dispatch)("OPEN_MODAL")(<NewMapTemplateForm />);

  return (
    <div className="container">
      <h2>Maps</h2>
      <button className="btn btn-primary" onClick={openModal}>
        Create New Map Template
      </button>
      <MapTable />
    </div>
  );
};

export default MapsPage;
