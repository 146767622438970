import React from "react";

const ExternalLink = ({ title, href }) => {
  const hasHttp = href.startsWith("http");
  return (
    <a
      href={hasHttp ? `${href}` : `http://${href}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
    </a>
  );
};

export default ExternalLink;
