import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "../context/StoreProvider";
import { useUser } from "../hooks/userHooks";
import Login from "./modal/login/Login";

const Footer = () => {
  const user = useUser();
  const dispatch = useDispatch();

  const toggleModal = (location) => {
    dispatch({ type: "OPEN_MODAL", payload: location });
  };

  let linkClasses = "d-block text-light btn btn-link";
  return (
    <footer className="footer text-center py-3 bg-dark">
      <a className={linkClasses} href="https://www.valueprop.com">
        Value Prop Home Page
      </a>

      {!user && (
        <React.Fragment>
          <div className={linkClasses} onClick={() => toggleModal(<Login />)}>
            Login
          </div>
          {/* <div className={linkClasses} onClick={() => toggleModal("signUp")}>
            Not a member? Join Us
          </div> */}
        </React.Fragment>
      )}
      {user && (
        <Link className={linkClasses} to="/logout">
          Log Out
        </Link>
      )}
      {/* <Link className={linkClasses} to="/contact-us">
        Contact Us{" "}
      </Link> */}
      {/* <a className={linkClasses} href="https://www.youtube.com/t/terms">
        YouTube Terms of Service (ToS)
      </a> */}
      <br />
      <p className={linkClasses}>
        © {new Date().getFullYear()} Concept Connect Ventures, LLC | RTA was
        developed with Doug Crisman and Oldhorses, Inc.
      </p>
    </footer>
  );
};

export default Footer;
