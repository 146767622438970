import React from "react";
import { withRouter } from "react-router";
import { useCompany, useMap } from "../../hooks/companyHooks";
import { Pusher } from "../../context/PusherProvider";
import { useQueryCache } from "react-query";
import CompanyHeader from "../companyMapManagement/CompanyHeader";
import { Link } from "react-router-dom";
import ActionBlock from "./ActionBlock";

const R = require("ramda");

const CriticalPath = ({ match }) => {
  const company = useCompany(match.params.companyId);
  const map = useMap(company, match.params.mapId);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const cache = useQueryCache();

  const updateMap = async () => {
    try {
      await cache.refetchQueries(["company"]);
    } catch (ex) {
      console.log(ex);
    }
  };

  React.useEffect(() => {
    if (!map) return;
    let active = true;
    const composed = R.compose(
      // R.slice(0, 4),
      R.sortWith([
        R.ascend(R.prop("score")),
        R.descend(R.prop("scoringWeight")),
      ]),
      R.flatten,
      R.map((cat) =>
        R.map((field) => ({
          ...field,
          catId: cat._id,
          catName: cat.name,
          score: field.score ? field.score : 0,
        }))(cat.fields)
      )
    )(map.categories);

    const reds = composed.filter((i) => i.score < 0);

    const yellows = composed.filter((i) => i.score === 0 || !i.score);

    const greens = composed.filter((i) => i.score > 0);

    if (active)
      setFilteredItems(
        R.slice(0, 4)([...reds, ...yellows, ...R.reverse(greens)])
      );

    return () => {
      active = false;
    };
  }, [map]);

  if (!filteredItems.length) return null;

  return (
    <div className="mx-5 mb-5">
      {company && (
        <>
          <Pusher
            channel="valueProp"
            event={company._id}
            onUpdate={() => updateMap()}
          />
          <CompanyHeader company={company} map={map} />
        </>
      )}

      <Link className="btn-link h5" to={`/map/${company._id}/${map._id}`}>
        View Entire Map
      </Link>
      <h3 className="text-center">Critical Path - Transformative Actions</h3>
      <div className="">
        {filteredItems.map((field) => (
          <ActionBlock
            key={field._id}
            field={field}
            map={map}
            company={company}
          />
        ))}
      </div>
    </div>
  );
};

export default withRouter(CriticalPath);
