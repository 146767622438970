import React from "react";

const GroupLabel = ({ label, color }) => {
  return (
    <div className="row">
      <div className="col-12 py-1">
        <h3 className="text-center ">{label} <span className={`btn bg-${color} btn-circle ml-3`} /></h3>
      </div>
    </div>
  );
};

export default GroupLabel;
