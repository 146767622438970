import React from "react";
import Table from "../common/hooksTable/Table";
// import Search from "../common/Search";
import Loader from "../common/Loader";
import { updateUser, deleteUser } from "../../services/userService";
import { isAdmin } from "../../utils/functions/userFunctions";
import { useGeneralMutation } from "../../hooks/useGeneralMutation";
import { getCurrentUser } from "../../services/authService";
import { useAllUsers } from "../../hooks/userHooks";
import { stringSearch } from "../../utils/functions/generalFunctions";
import Search from "../common/search";
import { useDispatch, storeUpdater } from "../../context/StoreProvider";
import SignUpForm from "../modal/signUp/SignUpForm";

const UsersTable = () => {
  const [filtered, setFiltered, allUsers] = useAllUsers();
  const [query, setQuery] = React.useState("");

  const doUpdateUser = useGeneralMutation(updateUser, ["allUsers"]);
  const doDeleteUser = useGeneralMutation(deleteUser, ["allUsers"]);

  const dispatch = useDispatch();
  const openModal = storeUpdater(dispatch)("OPEN_MODAL");

  const toggleAdmin = async (e, user) => {
    e.preventDefault();

    const newPermissions = user.permissions.includes("admin")
      ? user.permissions.filter((p) => p !== "admin")
      : [...user.permissions, "admin"];

    try {
      await doUpdateUser({
        userId: user._id,
        updates: { permissions: newPermissions },
      });
    } catch (ex) {
      console.log(ex.message);
    }
  };

  const handleDeleteUser = async (user) => {
    if(!window.confirm("Are you sure you want to delete this user?")) return
    try {
      await doDeleteUser(user._id);
    } catch (ex) {
      console.log(ex.message);
    }
  }



  const handleSearch = (value) => {
    setQuery(value);
    setFiltered(
      allUsers.filter((user) =>
        stringSearch(user.nameLast + user.nameFirst + user.email)(value)
      )
    );
  };

  if (!allUsers.length) return <Loader />;
  const columns = defineColumns(toggleAdmin, handleDeleteUser);

  return (
    <div className="container">
      <h1>Users</h1>
    <button className="btn btn-primary mb-4" onClick={()=>openModal(<SignUpForm login={false}/>)}>Add User</button>
      <Search onChange={handleSearch} value={query} />

      <Table
        columns={columns}
        data={filtered}
        initialSort="nameLast"
        sort={true}
      />
    </div>
  );
};

export default UsersTable;

const defineColumns = (toggleAdmin, deleteUser) => [
  {
    path: "nameLast",
    label: "Last Name",
  },
  { path: "nameFirst", label: "First Name" },
  { path: "email", label: "Email" },
  {
    path: "permissions",
    label: "Admin",
    content: (user) => {
      if (user._id === getCurrentUser()._id) return null;
      return (
        <input
          type="checkbox"
          name={`admin${user._id}`}
          id={user._id}
          checked={isAdmin(user)}
          onChange={(e) => toggleAdmin(e, user)}
        />
      );
    },
  },
  {
    path: "delete",
    label: "Delete",
    content: (user) => {

      if (user._id === getCurrentUser()._id) return null;
      return (
        <button className='btn btn-sm btn-danger'
          onClick={() => deleteUser( user)}
        >Delete User</button>
      );
    }
  }
];
