import React, { useState } from "react";
import Table from "../common/hooksTable/Table";
import { Link } from "react-router-dom";
import Loader from "../common/Loader";
import { useMapTemplates } from "../../hooks/mapHooks";
import { stringSearch } from "../../utils/functions/generalFunctions";
import Search from "../common/search";
import { strToLocal } from "../../utils/functions/dateFunctions";
import { useGeneralMutation } from "../../hooks/useGeneralMutation";
import { deleteMap } from "../../services/mapService";
const R = require("ramda");

const MapTable = () => {
  const [query, setQuery] = useState("");
  const [filtered, setFiltered, allMaps] = useMapTemplates();
  const doDeleteMap = useGeneralMutation(deleteMap, ["maps"]);

  const handleSearch = (value) => {
    setQuery(value);
    setFiltered(allMaps.filter((map) => stringSearch(map.name)(value)));
  };

  const delMap = async (map) => {
    if (!window.confirm("Are you sure you want to delete this map template?"))
      return true;
    try {
      await doDeleteMap(map._id);
    } catch (ex) {
      console.log(ex.message);
    }
  };

  const columns = [
    {
      path: "name",
      label: "Map Description",
      content: (map) => {
        return <Link to={`/admin/maps/edit/${map._id}`}>{map.name}</Link>;
      },
    },
    {
      path: "creationDate",
      label: "Date Created",
      content: (map) => strToLocal(map.creationDate)("en"),
    },
    {
      path: "delete",
      label: "Delete",
      content: (map) => (
        <i
          className={`fa fa-times text-danger`}
          style={{ cursor: "pointer" }}
          onClick={() => delMap(map)}
        />
      ),
    },
  ];

  if (R.isEmpty(allMaps)) return <Loader />;

  return (
    <div className="container my-2">
      <Search onChange={handleSearch} value={query} />
      <Table
        columns={columns}
        data={filtered}
        initialDirection={false}
        initialSort="creationDate"
        sort={true}
      />
    </div>
  );
};

export default MapTable;
