import React from "react";
import { useCompany, useMap } from "../../hooks/companyHooks";
import { isOdd } from "../../utils/functions/generalFunctions";
import PopUpBlockDisplay from "./PopUpBlockDisplay";
import ScoreSelector from "./ScoreSelector";
import VideoPlayer from "./VideoPlayer";

const FieldPopUp = ({ fieldId, catId, mapId, companyId }) => {
  const company = useCompany(companyId);
  const map = useMap(company, mapId);
  const category = map.categories.find((c) => c._id === catId);
  const field = category.fields.find((f) => f._id === fieldId);
  const [video, setVideo] = React.useState(null);

  return (
    <div style={{ minWidth: "85vw" }} className="text-left">
      <div className="container">
        <h3 className="mb-4">
          {category.name} | <strong>{field.name}</strong>{" "}
          <ScoreSelector
            field={field}
            catId={catId}
            map={map}
            company={company}
          />
        </h3>
        {video ? (
          <VideoPlayer onClose={() => setVideo(null)} videoId={video} />
        ) : (
          <>
            {field.videoUrl && (
              <h4 className="btn-link" onClick={() => setVideo(field.videoUrl)}>
                Video Explanation
              </h4>
            )}
            <div className="d-flex justify-content-between flex-wrap">
              {field.blocks.map((block, index) => (
                <div
                  key={block._id}
                  className="px-0 my-3 border border-1"
                  style={{
                    width:
                      index === field.blocks.length - 1 && !isOdd(index)
                        ? "100%"
                        : "48%",
                  }}
                >
                  <PopUpBlockDisplay block={block} setVideo={setVideo} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FieldPopUp;
