import React, { useState } from "react";
import Joi from "joi-browser";
import Flash, { useFlash } from "../common/Flash";
import useForm from "../common/hooksForm/useForm";
import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import { createCompany } from "../../services/companyService";
import { useGeneralMutation } from "../../hooks/useGeneralMutation";

const NewCompanyForm = () => {
  const dispatch = useDispatch();
  const closeModal = storeUpdater(dispatch)("CLOSE_MODAL");
  const [initialValues] = useState({});
  const [flash, setFlash] = useFlash();

  const doCreateCompany = useGeneralMutation(createCompany, ["companies"]);

  const handleSubmit = async (values) => {
    const newCompany = {
      name: values.name,
      primaryContact: {
        name: values.contactName,
        email: values.email,
        phone: values.phone,
      },
    };
    try {
      await doCreateCompany(newCompany);
      closeModal();
    } catch (ex) {
      setFlash(ex.message, "danger");
      return true;
    }
  };

  const form = useForm(handleSubmit, schema, initialValues);

  return (
    <div className="container">
      <h3>New Company</h3>
      <form className="needs-validation" onSubmit={form.handleSubmit}>
        {form.renderInput({ name: "name", label: "Company Name" })}
        <p>Primary Contact:</p>
        {form.renderInput({ name: "contactName", label: "Name" })}
        {form.renderInput({ name: "email", label: "Email" })}
        {form.renderInput({ name: "phone", label: "Phone Number" })}
        <Flash flash={flash} />
        {form.renderButton({ name: "create", label: "Create Company" })}
      </form>
    </div>
  );
};

export default NewCompanyForm;

const schema = {
  name: Joi.string().required(),
  contactName: Joi.string().allow(""),
  email: Joi.string().allow(""),
  phone: Joi.string().allow(""),
};
