import React from "react";
import { storeUpdater, useDispatch } from "../../context/StoreProvider";
import { isAdmin } from "../../utils/functions/userFunctions";
import Loader from "../common/Loader";
import NewCompanyForm from "../companyManagement/NewCompanyForm";
import CompanyTable from "../companyManagement/CompanyTable";
import { useUser } from "../../hooks/userHooks";

const Admin = () => {
  const user = useUser("permissions");
  const dispatch = useDispatch();
  const openModal = () =>
    storeUpdater(dispatch)("OPEN_MODAL")(<NewCompanyForm />);

  if (!user) return <Loader />;
  return (
    <div className="container">
      {isAdmin(user) && (
        <button className="btn btn-primary my-2" onClick={openModal}>
          Add Company
        </button>
      )}
      <CompanyTable />
    </div>
  );
};

export default Admin;
